// src/components/Header.js
import React from "react";
import { motion } from "framer-motion";

const Header = () => {
  return (
    <motion.header
      className="header"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.1 }}
      data-aos="fade-down"
    >
      <div className="profile">
        <img
          src="/assets/profile.gif"
          alt="Foto de Thomas Solcia"
          className="profile-pic"
          loading="lazy"
        />
        <div className="info">
          <h1>Thomas Solcia</h1>
          <p>Desenvolvedor</p>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
