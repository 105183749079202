// src/components/Badges.js
import React from "react";

/* eslint-disable no-script-url */
const badges = [
  {
    className: "badge--js",
    icon: "fa-brands fa-js",
    text: "JS",
    tooltip: "JavaScript: Desenvolvimento frontend e backend",
  },
  {
    className: "badge--html",
    icon: "fa-brands fa-html5",
    text: "HTML",
    tooltip: "HTML5: Estruturação de páginas web",
  },
  {
    className: "badge--python",
    icon: "fa-brands fa-python",
    text: "Python",
    tooltip: "Python: Desenvolvimento backend e automação",
  },
  {
    className: "badge--node",
    icon: "fa-brands fa-node-js",
    text: "Node",
    tooltip: "Node.js: Desenvolvimento backend com JavaScript",
  },
  {
    className: "badge--java",
    icon: "fa-brands fa-java",
    text: "Java",
    tooltip: "Java: Desenvolvimento de aplicações",
  },
  {
    className: "badge--delphi",
    icon: "fa-solid fa-code",
    text: "Delphi",
    tooltip: "Delphi: Desenvolvimento de aplicações",
  },
];

const Badges = () => {
  return (
    <section className="badges" aria-label="Habilidades" data-aos="fade-up">
      {badges.map((badge) => (
        <span
          key={badge.text}
          className={`badge ${badge.className}`}
          data-tooltip={badge.tooltip}
        >
          <i className={badge.icon} aria-hidden="true"></i> {badge.text}
        </span>
      ))}
    </section>
  );
};

export default Badges;
