// src/components/LoadingSpinner.js
import React, { useState, useEffect } from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ loading }) => {
  const [shouldRender, setShouldRender] = useState(loading);

  useEffect(() => {
    if (!loading) {
      // Aplica o fade-out e remove o componente após 300ms
      const timer = setTimeout(() => setShouldRender(false), 300);
      return () => clearTimeout(timer);
    } else {
      setShouldRender(true);
    }
  }, [loading]);

  if (!shouldRender) return null;

  return (
    <div
      className={`loading-spinner ${!loading ? "fade-out" : ""}`}
      aria-hidden="true"
    >
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
