// src/components/Links.js
import React from "react";

const links = [
  {
    href: "https://github.com/thomassolcia",
    label: "Acesse meu perfil no GitHub",
    title: "GitHub",
    icon: "fa-brands fa-github",
    description: "Acompanhe meus projetos no GitHub.",
  },
  {
    href: "https://open.spotify.com/intl-pt/artist/2gtJYSZI5eXOsVPMnBsV62",
    label: "Acesse meu perfil no Spotify",
    title: "Spotify",
    icon: "fa-brands fa-spotify",
    description: "Conheça meu projeto musical.",
  },
  {
    href: "https://cinemance.com/",
    label: "Acesse meu projeto Cinemance",
    title: "Cinemance",
    icon: "fa-solid fa-film",
    description: "Participe do meu projeto voltado ao cinema.",
  },
  {
    href: "https://instagram.com/thomas.solcia",
    label: "Acesse meu perfil no Instagram",
    title: "Instagram",
    icon: "fa-brands fa-instagram",
    description: "Siga-me no Instagram.",
  },
];

const Links = () => {
  return (
    <section
      className="links"
      aria-label="Links Importantes"
      data-aos="fade-up"
    >
      {links.map((link, index) => (
        <a
          key={index}
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          className="link-item"
          aria-label={link.label}
        >
          <h2>
            <i className={link.icon} aria-hidden="true"></i> {link.title}
          </h2>
          <p>{link.description}</p>
        </a>
      ))}
    </section>
  );
};

export default Links;
