// src/components/Popup.js
import React from "react";

const Popup = ({ message, visible }) => {
  return (
    <div
      id="popup"
      className={`popup ${visible ? "show" : "hidden"}`}
      role="alert"
    >
      <p>{message}</p>
    </div>
  );
};

export default Popup;
