// src/components/SocialLinks.js
import React from "react";

const SocialLinks = ({ showPopup }) => {
  const handleDiscordClick = () => {
    navigator.clipboard
      .writeText("awo")
      .then(() => showPopup("Username copiado para a área de transferência!"))
      .catch((err) =>
        console.error(
          "Erro ao copiar o nome para a área de transferência:",
          err
        )
      );
  };

  return (
    <section
      className="social-links"
      aria-label="Redes Sociais"
      data-aos="fade-up"
    >
      <button
        className="social-link"
        aria-label="Copiar meu nome no Discord"
        data-tooltip="Discord"
        onClick={handleDiscordClick}
      >
        <i className="fa-brands fa-discord" aria-hidden="true"></i>
      </button>
      <a
        href="mailto:thomas@awo.dev"
        className="social-link"
        aria-label="Enviar email"
        data-tooltip="Enviar Email"
      >
        <i className="fa-solid fa-envelope" aria-hidden="true"></i>
      </a>
    </section>
  );
};

export default SocialLinks;
