// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";

import LoadingSpinner from "./components/LoadingSpinner";
import ThemeToggle from "./components/ThemeToggle";
import Header from "./components/Header";
import Badges from "./components/Badges";
import Links from "./components/Links";
import SocialLinks from "./components/SocialLinks";
import Footer from "./components/Footer";
import Popup from "./components/Popup";

function App() {
  // Estado de carregamento do site
  const [loading, setLoading] = useState(true);

  // Estado do tema (light ou dark)
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) return savedTheme;
    const prefersLight = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches;
    return prefersLight ? "light" : "dark";
  });

  // Estados para o popup de notificação
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  // Inicializa o AOS para animações on scroll
  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);

  // Verifica se a página foi completamente carregada (imagens, CSS, etc.)
  useEffect(() => {
    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      const handleLoad = () => setLoading(false);
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  // Atualiza o tema no body e no localStorage
  useEffect(() => {
    document.body.classList.remove("light", "dark");
    document.body.classList.add(theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  const showPopup = (message) => {
    setPopupMessage(message);
    setPopupVisible(true);
    setTimeout(() => setPopupVisible(false), 3000);
  };

  // Enquanto loading for true, exibe somente o spinner
  if (loading) {
    return <LoadingSpinner loading={loading} />;
  }

  return (
    <Router basename="/">
      <Routes>
        {/* Rota principal */}
        <Route
          path="/"
          element={
            <>
              <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
              <Popup message={popupMessage} visible={popupVisible} />
              <div className="container">
                <Header />
                <main>
                  <Badges />
                  <Links />
                  <SocialLinks showPopup={showPopup} />
                </main>
                <Footer />
              </div>
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
