import React from "react";

const ThemeToggle = ({ theme, toggleTheme }) => {
  return (
    <button
      className="toggle-theme"
      aria-label="Alternar tema"
      onClick={toggleTheme}
    >
      <i className={`fas ${theme === "light" ? "fa-sun" : "fa-moon"}`}></i>
    </button>
  );
};

export default ThemeToggle;
