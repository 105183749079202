import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer" data-aos="fade-up">
      <p>&copy; {currentYear} Thomas Solcia.</p>
    </footer>
  );
};

export default Footer;
